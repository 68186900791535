@import '../variables.scss';

.icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    
    svg {
        width: 32px;
        height: 32px;
        transition: fill 0.15s ease-in-out;
        
        &:hover {
            fill: $shiba-color;
            cursor: pointer;
        }

        @media screen and (max-width: $media-breakpoint) {
            width: 21px;
            height: 21px;
        }
    }

    @media screen and (max-width: $media-breakpoint) {
        gap: 13px;
    }
}