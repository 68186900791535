@import '../variables.scss';

.shiba-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    margin-top: 70px;
    
    svg.active {
        cursor: pointer;
        transition: all 0.2s;

        &:active {
            transform: scale(0.9);
        }
    }

    @media screen and (max-width: $media-breakpoint) {
        height: 250px;
    }
}
