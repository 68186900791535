@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300;500;600;700&display=swap');
@import './variables.scss';

html, #root, body {
    height: 100%;
}

body {
    font-family: 'Signika Negative', sans-serif;
    color: #2d2d2d;
}

h1, h2, h3 {
    margin: 10px;
}

h1 {
    font-size: 60px;

    @media screen and (max-width: $media-breakpoint) {
        font-size: 40px;
    }
}

h2 {
    font-size: 45px;

    @media screen and (max-width: $media-breakpoint) {
        font-size: 30px;
    }
}

h3 {
    font-size: 35px;

    @media screen and (max-width: $media-breakpoint) {
        font-size: 20px;
    }
}

.underline {
    background-color: $shiba-color;
    position: relative;
    z-index: -1;
    margin: 0 auto;
    width: 440px;
    bottom: 27px;
    height: 10px;

    @media screen and (max-width: $media-breakpoint) {
        width: 290px;
        bottom: 21px;
        height: 6px;
    }
}

.shiba {
    font-family: $shiba-font;
    color: $shiba-color
}

.App {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    text-align: center;
    height: 100%;
}
